import React from 'react';
import { shape, string } from 'prop-types';

import StyledLabel from './wrapped-styled-label';
import { namespace } from '../common';
import getIcon from '../../../../components-v2/icons';

export const ContentDetails = ({ content }) => {
  const { label, title, subtitle } = content || {};

  return (
    <>
      {label?.text && (
        <div className={`${namespace}__content-label-container`}>
          <StyledLabel as="span" className={`${namespace}__content-label`} {...label} />
          {getIcon({ iconId: 'cockade', className: `${namespace}__cockade` })}
        </div>
      )}
      {title?.text && <StyledLabel as="span" className={`${namespace}__content-title`} {...title} />}
      {subtitle?.text && <StyledLabel as="p" className={`${namespace}__content-subtitle`} {...subtitle} />}
    </>
  );
};

ContentDetails.propTypes = {
  content: shape({
    label: shape({
      text: string,
    }),
    title: shape({
      text: string,
    }),
    subtitle: shape({
      text: string,
    }),
  }),
};
