import React from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';

import { defaultPropsIntervention, namespace } from './common';
import { Title } from './components/title';
import { ContentDetails } from './components/content-details';
import PictureThumbnail from './components/picture-thumbnail';
import { Footer } from './components/footer';
import WrappedStyledLabel from './components/wrapped-styled-label';

const QuickAccessIntervention = ({ intervention = defaultPropsIntervention }) => {
  const { content, action } = intervention;

  return (
    <div className={classnames(namespace, `${namespace}-container`)}>
      <Title title={intervention?.title} />
      <div className={`${namespace}-wrapper`}>
        <PictureThumbnail size="96" picture={content?.picture} />
        <div className={`${namespace}__content`}>
          <ContentDetails content={content} />
        </div>
        <Footer buttonHierarchy="quiet" components={content?.components} action={action}>
          <WrappedStyledLabel {...action?.title} as="label" />
        </Footer>
      </div>
    </div>
  );
};

QuickAccessIntervention.propTypes = {
  intervention: shape({
    background_color: string,
    content: shape({}),
  }),
};

export default QuickAccessIntervention;
