import React from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';

import { defaultPropsIntervention, namespace } from './common';
import { Title } from './components/title';
import { ContentDetails } from './components/content-details';
import PictureThumbnail from './components/picture-thumbnail';
import { Footer } from './components/footer';
import WrappedStyledLabel from './components/wrapped-styled-label';
import getIcon from '../../../components-v2/icons';

const QuickAccessIntervention = ({ intervention = defaultPropsIntervention }) => {
  const { content, action, background_color } = intervention;

  return (
    <div className={classnames(namespace)}>
      <div
        className={classnames(`${namespace}-container`, {
          [`${namespace}-${background_color}`]: background_color,
        })}
      >
        <Title title={intervention?.title} />
        <div className={`${namespace}-wrapper`}>
          <PictureThumbnail size="48" picture={content?.picture} />
          <div className={`${namespace}__content`}>
            <ContentDetails content={content} />
            <Footer components={content?.components} action={action} buttonHierarchy="transparent" buttonSize="small">
              <WrappedStyledLabel className={`${namespace}__action-text`} as="label" {...action?.title} />
              <div className={`${namespace}__chevron-container`}>
                {getIcon({ iconId: 'chevron', className: `${namespace}__chevron` })}
              </div>
            </Footer>
          </div>
        </div>
      </div>
    </div>
  );
};

QuickAccessIntervention.propTypes = {
  intervention: shape({
    background_color: string,
    content: shape({}),
  }),
};

export default QuickAccessIntervention;
