import React from 'react';
import { shape, array, string, arrayOf, oneOf } from 'prop-types';

import classnames from 'classnames';

import { namespace } from './common';
import componentFactoryFrom from '../../pills-builder/factory';
import LabelBuilder from '../../../components-v2/label-builder';

const LabelComponent = ({ label }) => {
  const { text, values, styles: parentStyles } = label ?? {};
  const parentColor = parentStyles?.color;

  const class_name = classnames({
    [`ui-search-size--${label?.styles?.font_size}`]: !!label?.styles?.font_size,
    [`ui-search-weight--${label?.styles?.font_weight}`]: !!label?.styles?.font_weight,
  });

  const newValues = values.map(({ styles = {}, ...remainingProps }) => {
    const className = classnames({
      [`ui-search-size--${styles?.font_size}`]: !!styles?.font_size,
      [`ui-search-weight--${styles?.font_weight}`]: !!styles?.font_weight,
    });

    return { className, styles: { color: styles.color ?? parentColor }, ...remainingProps };
  });

  return (
    <LabelBuilder
      html_tag="label"
      text={text}
      values={newValues}
      class_name={class_name}
      styles={{ color: parentColor }}
    />
  );
};

const styleShape = shape({
  font_size: string,
  font_weight: string,
  color: string,
});

LabelComponent.propTypes = {
  label: shape({
    text: string,
    styles: styleShape,
    values: arrayOf(
      shape({
        type: oneOf(['text']),
        styleShape,
      }),
    ),
  }),
};

const SOURCES = {
  VERTICAL_SEPARATOR: () => <div className={`${namespace}-separator`} />,
  LABEL_COMPONENT: LabelComponent,
};

const Component = componentFactoryFrom(SOURCES);

Component.propTypes = {
  id: string,
  type: oneOf(['LABEL_COMPONENT', 'VERTICAL_SEPARATOR']).isRequired,
};

export const Components = ({ components }) =>
  components?.length > 0 && (
    <div className={`${namespace}__components-container`}>
      {components.map(({ id, key: _, ...props }) => (
        <Component key={id} {...props} />
      ))}
    </div>
  );

Components.propTypes = {
  components: array,
};
