import React from 'react';
import { shape, string } from 'prop-types';

import { Thumbnail } from '@andes/thumbnail';

import { renderPicture, namespace } from '../common';

const PictureThumbnail = ({ picture, size }) => {
  if (!picture?.type) {
    return null;
  }

  return (
    <Thumbnail size={size} modifier="square" className={`${namespace}__img-container`}>
      {renderPicture(picture)}
    </Thumbnail>
  );
};

PictureThumbnail.propTypes = {
  picture: shape({
    type: string.isRequired,
  }),
  size: string.isRequired,
};

export default PictureThumbnail;
