import React from 'react';
import { shape, string, array, node } from 'prop-types';

import { Button } from '@andes/button';

import { Components } from '../Components';
import { handleClick, namespace } from '../common';

export const Footer = ({ components, action, children, buttonHierarchy, buttonSize }) => (
  <div className={`${namespace}__footer`}>
    <Components components={components} />
    {action?.title && (
      <Button
        className={`${namespace}__action`}
        href={action.target}
        loading={false}
        onClick={handleClick(action?.melidata_tracks)}
        hierarchy={buttonHierarchy}
        size={buttonSize}
      >
        {children}
      </Button>
    )}
  </div>
);

Footer.propTypes = {
  action: shape({
    target: string.isRequired,
    title: shape({
      text: string.isRequired,
    }),
  }),
  buttonHierarchy: string,
  buttonSize: string,
  children: node,
  components: array,
};
