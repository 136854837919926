import React from 'react';

import { Image } from 'nordic/image';

import { trackEvent } from '../../../lib/melidata';
import getIcon from '../../../components-v2/icons/utils/get-icon';

export const namespace = 'ui-search-quick-access-intervention';
export const defaultPropsIntervention = {};

export const renderPicture = (picture) => {
  switch (picture.type) {
    case 'url':
      return <Image alt="" src={picture?.url} className={`${namespace}__img`} role="presentation" />;
    case 'icon': {
      const props = {
        className: `${namespace}__icon`,
        role: 'presentation',
        iconId: picture.url,
      };
      const fallbackElement = <span />;

      return getIcon(props) ?? fallbackElement;
    }

    default:
      return null;
  }
};

export const handleClick = (melidata) => () => {
  if (!melidata) {
    return;
  }

  const { path, event_data, experiments } = melidata;

  trackEvent({
    path,
    event_data,
    experiments,
    clean: true,
  });
};
