import React from 'react';
import { shape, string } from 'prop-types';

import StyledLabel from './wrapped-styled-label';
import { namespace } from '../common';

export const Title = ({ title }) => title?.text && <StyledLabel as="h2" {...title} className={`${namespace}__title`} />;

Title.propTypes = {
  title: shape({
    text: string,
  }),
};
