import React from 'react';

import LabelBuilder from '../../../../components-v2/label-builder';

const mapper = (props) => {
  const { styles, className } = props;
  const { font_size, font_weight, ...restStyles } = styles;

  const outProps = {
    class_name: className,
    text: props.text,
    html_tag: props.as,
    styles: restStyles,
    styleLabel: {
      font_size,
      font_family: font_weight,
    },
  };

  return outProps;
};

const WrappedStyledLabel = (props) => <LabelBuilder {...mapper(props)} />;

export default WrappedStyledLabel;
